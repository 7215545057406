import { useSessionActions } from 'features/Auth/hook/session';
import { useEffect } from 'react';
import { History, URL } from 'Urls';
import log from 'loglevel';

export function LogoutView() {
  const { clearSessionData } = useSessionActions();
  useEffect(() => {
    log.debug('Log user out');
    try {
      clearSessionData();
    } finally {
      setTimeout(() => {
        History.push(URL.ROOT);
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
