import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import { useEffect, useMemo, useRef, useReducer, useCallback } from 'react';
import logger from 'loglevel';
import { AuthenticationService } from 'api/AuthenticationService';
import { KeycloakState } from './types';
import { initialContextValue, KeycloakContext } from './KeycloakContext';
import { History, PublicURL } from 'Urls';

type Action =
  | { type: 'SET_KEYCLOAK'; payload: Keycloak }
  | { type: 'SET_AUTHENTICATED'; payload: boolean }
  | { type: 'SET_LOADING'; payload: boolean };

const reducer = (state: KeycloakState, action: Action): KeycloakState => {
  switch (action.type) {
    case 'SET_KEYCLOAK':
      return { ...state, keycloak: action.payload };
    case 'SET_AUTHENTICATED':
      return { ...state, isAuthenticated: action.payload };
    case 'SET_LOADING':
      return { ...state, isAuthLoading: action.payload };
    default:
      return state;
  }
};

type IKeycloakProvider = {
  keycloakConfig: Keycloak.KeycloakConfig;
  initOptions: KeycloakInitOptions;
  children: React.ReactNode;
};

export const KeycloakProvider: React.FunctionComponent<IKeycloakProvider> = (
  props: IKeycloakProvider,
) => {
  const { keycloakConfig, initOptions, children } = props;

  const didLogRef = useRef(false);
  const [state, dispatch] = useReducer(reducer, initialContextValue);
  const authService = AuthenticationService.getInstance();

  const initializeKeycloak = useCallback(async () => {
    if (!keycloakConfig.realm) {
      History.push(PublicURL.TENANT_SELECTION);
    }
    const keycloakInstance = new Keycloak(keycloakConfig);
    authService.setKeycloakInstance(keycloakInstance);
    try {
      const authenticated = await keycloakInstance.init({ ...initOptions });
      if (!authenticated) {
        const urlParams = new URLSearchParams(window.location.search);

        await keycloakInstance.login({
          loginHint: urlParams.get('loginHint') ?? undefined,
        });
      }
      authService.setIsAuthenticated(authenticated);
      logger.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
      dispatch({ type: 'SET_KEYCLOAK', payload: keycloakInstance });
      dispatch({ type: 'SET_AUTHENTICATED', payload: authenticated });
    } catch (error) {
      logger.error('Failed to initialize Keycloak:', error);
      authService.setIsAuthenticated(false);
      dispatch({ type: 'SET_AUTHENTICATED', payload: false });
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  }, [keycloakConfig, authService, initOptions]);

  useEffect(() => {
    if (!didLogRef.current) {
      didLogRef.current = true;
      initializeKeycloak();
    }
  }, [initializeKeycloak]);

  const contextValue = useMemo(() => state, [state]);

  return (
    <KeycloakContext.Provider value={contextValue}>{children}</KeycloakContext.Provider>
  );
};
