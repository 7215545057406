import { createBrowserHistory } from 'history';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FeatureFlagsKeys } from 'hooks/useFeatureFlags';
import { TenantFeatureFlagsKeys } from 'types/Common';
import { PermissionType } from 'features/Auth/Permissions';
import { icons } from 'utils/icons';

export type UrlRoute = {
  name: string;
  path: string;
  component: () => JSX.Element | null;
  requiredPermission?: PermissionType;
  requiredFeatureFlags?: FeatureFlagsKeys[];
  requiredTenantFlags?: TenantFeatureFlagsKeys[];
};

export const History = createBrowserHistory();

export const Layout = {
  PRIVATE: '',
  PUBLIC: '/auth',
  DOC: '/doc',
} as const;

// eslint-disable-next-line react-refresh/only-export-components
export const URL = {
  ROOT: '/',
  LOGOUT: '/users/logout',
  TENANT_SELECTION: '/tenant-selection',
  PASSWORD: '/users/password',
  AUTH_ERROR: '/auth_error',
  CONCEPT_LAB: '/concept-hub',
  CONCEPT_LAB_ANALYSIS_BY_ID: '/analysis/:analysisId/details',
  CONCEPT_LAB_CONCEPTS: '/analysis/:analysisId/concepts',
  CONCEPT_LAB_PROMOTE: '/analysis/:analysisId/promote',
  COMPLETE_SIGNUP: '/users/complete',
  MANAGE_USERS: '/users/manage',
  MANAGE_USERS_ROLES_RAW: '/users/manage/roles-raw',
  RESET_PASSWORD: '/users/recovery',
  USER_PROFILE: '/users/:userId',
  PROJECT_SETUP: '/projects/blank',
  PROJECT_UPDATE: '/projects/:projectId',
  TIMELINE: '/projects/:projectId/timeline',
  COMPONENTS: '/projects/:projectId/components',
  OVERVIEW: '/projects/:projectId/overview',
  VISION: '/projects/:projectId/vision',
  TVD: '/projects/:projectId/tvd',
  ESTIMATE: '/projects/:projectId/estimate',
  ESTIMATE_IMPORT: '/projects/:projectId/estimate-import',
  ESTIMATE_IMPORT_REVIEW:
    '/projects/:projectId/estimate-import-review/:milestoneEstimateUploadId',
  INVITE_MEMBERS: '/projects/:projectId/invite-members',
  FORESITE: '/projects/:projectId/foresite',
  FORESITE_VOI_DETAILS: '/projects/:projectId/foresite/value-option-details/:voiId',
  COMPARISON: '/projects/:projectId/comparison',
  CALIBRATE_DIVISION: '/projects/:projectId/calibrate/division',
  CALIBRATE: '/projects/:projectId/calibrate',
  AREA_SETUP: '/projects/:projectId/area-setup',
  RISKS: '/projects/:projectId/risks',
  PROCUREMENT: '/projects/:projectId/procurement',
  PROCUREMENT_WIZARD_BID_PACKAGES_SETUP:
    '/projects/:projectId/procurement/wizard/bid-packages-setup',
  PROCUREMENT_WIZARD_PURCHASE_PLAN:
    '/projects/:projectId/procurement/wizard/purchase-plan',
  PROCUREMENT_WIZARD_DOCUMENTATION:
    '/projects/:projectId/procurement/wizard/documentation',
  PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT:
    '/projects/:projectId/procurement/wizard/bid-package-development',
  PROCUREMENT_BID_PACKAGE_DETAILS:
    '/projects/:projectId/procurement/bid-package-details/:bidPackageId',
  BUILDING_CONNECTED: '/building-connected/',
  PROJECT_LIST: '/projects',
  COMPANY_PROFILE: '/companies/:companyId',
  PORTFOLIO: '/portfolio',
  MARKUP_TEMPLATES: '/markup-templates',
  PROJECT_DASHBOARD_LIST: '/project-dashboards',
  PROJECT_DASHBOARD_DETAIL: '/project-dashboards/:id',
  PROJECT_DASHBOARD_PORTFOLIO: '/project-dashboards/portfolio',
  ABOUT: '/about',
  LANDING: '/',
  SERVICES: '/services',
  CONTACTS: '/contacts',
  PROJECT_NEW_OPTIONS: '/projects/new-options',
  PROJECT_LEGACY_SETUP: '/projects/legacy/blank',
  PROJECT_LEGACY_UPDATE: '/projects/legacy/:projectId',
  PROJECT_LEGACY_COMPONENTS: '/projects/legacy/:projectId/components',
  PROJECT_LEGACY_ESTIMATE: '/projects/legacy/:projectId/estimate',
  FORESITE_LIBRARY: '/library',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  CUSTOM_CODES: '/custom-codes',
  FORBIDDEN: '/forbidden',
  REPORTS: '/projects/:projectId/reports',
  CONTEXT: '/projects/:projectId/context',
  ISSUE_MANAGEMENT: '/projects/:projectId/issues',
  PUBLIC_API_DOCUMENTATION: '/public-api/documentation',
  PUBLIC_API: '/public-api',
  CHANGELOG_LIST: '/changelog',
  CUSTOM_ATTRIBUTES: '/custom-attributes',
  UNITS_OF_MEASURE: '/units-of-measure',
} as const;

export const PublicURL = {
  LOGOUT: `${Layout.PUBLIC}${URL.LOGOUT}`,
  TENANT_SELECTION: `${Layout.PUBLIC}${URL.TENANT_SELECTION}`,
  PASSWORD: `${Layout.PUBLIC}${URL.PASSWORD}`,
  COMPLETE_SIGNUP: `${Layout.PUBLIC}${URL.COMPLETE_SIGNUP}`,
  RESET_PASSWORD: `${Layout.PUBLIC}${URL.RESET_PASSWORD}`,
  AUTH_ERROR: `${Layout.PUBLIC}${URL.AUTH_ERROR}`,
  OMNICLASS: '/omniclass.pdf',
  LANDING: 'https://concntric.com/',
  ABOUT: 'https://concntric.com/about/',
  SOLUTIONS: 'https://concntric.com/solutions/',
  CONTACTS: 'https://concntric.com/contact/',
  KNOWLEDGE_BASE: 'https://app.concntric.academy/',
  PRIVACY_POLICY: `${Layout.DOC}${URL.PRIVACY_POLICY}`,
} as const;

export const PrivateURL = {
  PROJECT_SETUP: `${Layout.PRIVATE}${URL.PROJECT_SETUP}`,
  PROJECT_UPDATE: `${Layout.PRIVATE}${URL.PROJECT_UPDATE}`,
  TIMELINE: `${Layout.PRIVATE}${URL.TIMELINE}`,
  PROJECT_LIST: `${Layout.PRIVATE}${URL.PROJECT_LIST}`,
  CONCEPT_LAB: `${Layout.PRIVATE}${URL.CONCEPT_LAB}`,
  CONCEPT_LAB_ANALYSIS_BY_ID: `${Layout.PRIVATE}${URL.CONCEPT_LAB_ANALYSIS_BY_ID}`,
  CONCEPT_LAB_CONCEPTS: `${Layout.PRIVATE}${URL.CONCEPT_LAB_CONCEPTS}`,
  CONCEPT_LAB_PROMOTE: `${Layout.PRIVATE}${URL.CONCEPT_LAB_PROMOTE}`,
  COMPONENTS: `${Layout.PRIVATE}${URL.COMPONENTS}`,
  VISION: `${Layout.PRIVATE}${URL.VISION}`,
  TVD: `${Layout.PRIVATE}${URL.TVD}`,
  OVERVIEW: `${Layout.PRIVATE}${URL.OVERVIEW}`,
  ESTIMATE: `${Layout.PRIVATE}${URL.ESTIMATE}`,
  ESTIMATE_IMPORT: `${Layout.PRIVATE}${URL.ESTIMATE_IMPORT}`,
  ESTIMATE_IMPORT_REVIEW: `${Layout.PRIVATE}${URL.ESTIMATE_IMPORT_REVIEW}`,
  INVITE_MEMBERS: `${Layout.PRIVATE}${URL.INVITE_MEMBERS}`,
  FORESITE: `${Layout.PRIVATE}${URL.FORESITE}`,
  FORESITE_VOI_DETAILS: `${Layout.PRIVATE}${URL.FORESITE_VOI_DETAILS}`,
  PROJECT_COMPARISON: `${Layout.PRIVATE}${URL.COMPARISON}`,
  COMPANY_PROFILE: `${Layout.PRIVATE}${URL.COMPANY_PROFILE}`,
  PORTFOLIO: `${Layout.PRIVATE}${URL.PORTFOLIO}`,
  MARKUP_TEMPLATES: `${Layout.PRIVATE}${URL.MARKUP_TEMPLATES}`,
  PROJECT_DASHBOARD_LIST: `${Layout.PRIVATE}${URL.PROJECT_DASHBOARD_LIST}`,
  PROJECT_DASHBOARD_DETAIL: `${Layout.PRIVATE}${URL.PROJECT_DASHBOARD_DETAIL}`,
  PROJECT_DASHBOARD_PORTFOLIO: `${Layout.PRIVATE}${URL.PROJECT_DASHBOARD_PORTFOLIO}`,
  USER_PROFILE: `${Layout.PRIVATE}${URL.USER_PROFILE}`,
  MANAGE_USERS: `${Layout.PRIVATE}${URL.MANAGE_USERS}`,
  MANAGE_USERS_ROLES_RAW: `${Layout.PRIVATE}${URL.MANAGE_USERS_ROLES_RAW}`,
  CALIBRATE: `${Layout.PRIVATE}${URL.CALIBRATE}`,
  CALIBRATE_DIVISION: `${Layout.PRIVATE}${URL.CALIBRATE_DIVISION}`,
  AREA_SETUP: `${Layout.PRIVATE}${URL.AREA_SETUP}`,
  RISKS: `${Layout.PRIVATE}${URL.RISKS}`,
  PROJECT_NEW_OPTIONS: `${Layout.PRIVATE}${URL.PROJECT_NEW_OPTIONS}`,
  PROJECT_LEGACY_SETUP: `${Layout.PRIVATE}${URL.PROJECT_LEGACY_SETUP}`,
  PROJECT_LEGACY_UPDATE: `${Layout.PRIVATE}${URL.PROJECT_LEGACY_UPDATE}`,
  PROJECT_LEGACY_COMPONENTS: `${Layout.PRIVATE}${URL.PROJECT_LEGACY_COMPONENTS}`,
  PROJECT_LEGACY_ESTIMATE: `${Layout.PRIVATE}${URL.PROJECT_LEGACY_ESTIMATE}`,
  FORESITE_LIBRARY: `${Layout.PRIVATE}${URL.FORESITE_LIBRARY}`,
  FORBIDDEN_VIEW: `${Layout.PRIVATE}${URL.FORBIDDEN}`,
  TERMS_AND_CONDITIONS: `${Layout.PRIVATE}${URL.TERMS_AND_CONDITIONS}`,
  CUSTOM_CODES: `${Layout.PRIVATE}${URL.CUSTOM_CODES}`,
  PROCUREMENT: `${Layout.PRIVATE}${URL.PROCUREMENT}`,
  PROCUREMENT_WIZARD_BID_PACKAGES_SETUP: `${Layout.PRIVATE}${URL.PROCUREMENT_WIZARD_BID_PACKAGES_SETUP}`,
  PROCUREMENT_WIZARD_PURCHASE_PLAN: `${Layout.PRIVATE}${URL.PROCUREMENT_WIZARD_PURCHASE_PLAN}`,
  PROCUREMENT_WIZARD_DOCUMENTATION: `${Layout.PRIVATE}${URL.PROCUREMENT_WIZARD_DOCUMENTATION}`,
  PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT: `${Layout.PRIVATE}${URL.PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT}`,
  PROCUREMENT_BID_PACKAGE_DETAILS: `${Layout.PRIVATE}${URL.PROCUREMENT_BID_PACKAGE_DETAILS}`,
  REPORTS: `${Layout.PRIVATE}${URL.REPORTS}`,
  BUILDING_CONNECTED: `${Layout.PRIVATE}${URL.BUILDING_CONNECTED}`,
  PUBLIC_API_DOCUMENTATION: `${Layout.PRIVATE}${URL.PUBLIC_API_DOCUMENTATION}`,
  PUBLIC_API: `${Layout.PRIVATE}${URL.PUBLIC_API}`,
  CONTEXT: `${Layout.PRIVATE}${URL.CONTEXT}`,
  ISSUE_MANAGEMENT: `${Layout.PRIVATE}${URL.ISSUE_MANAGEMENT}`,
  CHANGELOG_LIST: `${Layout.PRIVATE}${URL.CHANGELOG_LIST}`,
  CUSTOM_ATTRIBUTES: `${Layout.PRIVATE}${URL.CUSTOM_ATTRIBUTES}`,
  UNITS_OF_MEASURE: `${Layout.PRIVATE}${URL.UNITS_OF_MEASURE}`,
} as const;

export type PrivateURLKey = keyof typeof PrivateURL;
export type PrivateURLValue = (typeof PrivateURL)[PrivateURLKey];

export type PublicURLKey = keyof typeof PublicURL;
export type PublicURLValue = (typeof PublicURL)[PublicURLKey];

export type Section = {
  label: string;
  emptyLabel?: string;
  url: PrivateURLValue;
  icon?: IconProp;
  chipLabel?: string;
};

// eslint-disable-next-line react-refresh/only-export-components
export const projectSections: Record<string, Section> = {
  vision: {
    label: 'Vision',
    url: PrivateURL.VISION,
    icon: icons.vision,
  },
  foresite: {
    label: 'ForeSite',
    url: PrivateURL.FORESITE,
    icon: icons.foresite,
  },
  tvd: {
    label: 'Target Value Design',
    url: PrivateURL.TVD,
    icon: icons.tvd,
  },
  overview: {
    label: 'Overview',
    url: PrivateURL.OVERVIEW,
    icon: icons.overview,
  },
  procurement: {
    label: 'Procurement',
    url: PrivateURL.PROCUREMENT,
    icon: icons.procurement,
  },
  timeline: {
    label: 'Timeline',
    url: PrivateURL.TIMELINE,
    icon: icons.timeline,
  },
  calibrate: {
    label: 'Calibrate',
    url: PrivateURL.CALIBRATE,
    icon: icons.calibrate,
  },
  risks: {
    label: 'Risk Register',
    url: PrivateURL.RISKS,
    icon: icons.risk,
  },
  details: {
    label: 'Details',
    url: PrivateURL.PROJECT_UPDATE,
    icon: icons.projectDetail,
  },
  components: {
    label: 'Components',
    url: PrivateURL.COMPONENTS,
    icon: icons.components,
  },
  componentsSetup: {
    label: 'Component Setup',
    url: PrivateURL.COMPONENTS,
    icon: icons.components,
  },
  estimateUpdate: {
    label: 'Estimate',
    emptyLabel: 'Estimate Upload',
    url: PrivateURL.ESTIMATE,
    icon: icons.estimate,
  },
  estimateImport: {
    label: 'Upload Estimate',
    url: PrivateURL.ESTIMATE_IMPORT_REVIEW,
    icon: icons.estimate,
  },
  estimateImportReview: {
    label: 'Estimate Review',
    url: PrivateURL.ESTIMATE_IMPORT_REVIEW,
    icon: icons.estimate,
  },
  estimateSetup: {
    label: 'Estimate Setup',
    url: PrivateURL.ESTIMATE,
    icon: icons.estimate,
  },
  area: {
    label: 'Program',
    url: PrivateURL.AREA_SETUP,
    icon: icons.program,
  },
  reports: {
    label: 'Reporting',
    url: PrivateURL.REPORTS,
    icon: icons.reports,
  },
  context: {
    label: 'Context',
    url: PrivateURL.CONTEXT,
    icon: icons.context,
    chipLabel: 'Preview',
  },
  issueManagement: {
    label: 'Issue Management',
    url: PrivateURL.ISSUE_MANAGEMENT,
    icon: icons.issues,
  },
} as const;

export type SectionType = keyof typeof projectSections;

// eslint-disable-next-line react-refresh/only-export-components
export const getProjectSection = (url: PrivateURLKey) => {
  const privateUrl = PrivateURL[url];
  const section = Object.values(projectSections).find(
    (section) => section.url === privateUrl,
  ) as Section;
  return section?.emptyLabel ?? section?.label ?? '';
};

// eslint-disable-next-line react-refresh/only-export-components
export const ProcurementRequiredPermsRoute: Partial<UrlRoute> = {
  requiredTenantFlags: ['procurement_available'],
};
