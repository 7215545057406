import { Box, Link, Stack, Typography } from '@mui/material';
import { useSessionActions } from '../hook/session';
import { isDev } from 'utils/environment';
import { History, PublicURL } from 'Urls';

export const ErrorPage = () => {
  const { clearSessionData } = useSessionActions();
  const handleGoBack = () => {
    clearSessionData();
    // There may be the case where the use is trying to log in from an URL that
    // specify an invalid tenant
    const { host, protocol } = new URL(window.location.href);
    const hostParts = host.split('.');
    if (isDev() && hostParts.length > 0 && hostParts.at(-1)?.includes('localhost')) {
      window.location.replace(`${protocol}//app.${hostParts.at(-1)}`);
    } else if (hostParts.length >= 4) {
      window.location.replace(`${protocol}//${hostParts.slice(-3).join('.')}`);
    } else {
      History.push(PublicURL.LOGOUT);
    }
  };

  return (
    <Stack sx={{ justifyContent: 'center', p: 5, alignItems: 'center' }}>
      <Typography sx={{ mb: 2 }}>There was an error</Typography>
      <Box
        component={Link}
        onClick={handleGoBack}
        sx={(theme) => ({ color: `${theme.palette.primary.main} !important` })}
      >
        Go Back to Login
      </Box>
    </Stack>
  );
};
